
/**
 * Admin user actions only visible for administrators
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Prop, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ibomadeUserService from "@/services/ibomadeUserService";
import { Projects } from "@/services/projectService";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import UserAccess from "@/model/User/UserAccess";
import { User } from "@/stores/modules/UserList";

interface ActionsData {
  item: User;
}

@Component({
  name: "AdminActions",
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class AdminActions extends Mixins(confirmModal) {
  readonly getUserAccess!: UserAccess;
  /*----------  Props  ----------*/
  @Prop({ type: Object, default: () => ({}) }) data!: ActionsData;

  get isUserOrHavePermissionToEdit(): boolean {
    const isUser = this.data.item.roles?.includes("user") || false;
    return this.getUserAccess.Access.EDIT.usersList.actions.editUser && isUser;
  }

  checkCurrentUser(id: string) {
    return this.$keycloak.tokenParsed?.sub !== id;
  }
  viewUserDetails(item: any) {
    const userLink = `users/${item.id.toString()}`;
    this.$router.push({ path: userLink });
  }

  resetUserCredentials(userId: string) {
    this.confirm((this as any).$t("users.adminActionMsg.credentialResetConfirmation"), async () => {
      let res = await ibomadeUserService.resetUser(userId);
      if (res === 204) {
        this.updateUserList({
          class: "success",
          msg: this.$t("users.newUserForm.formStatusMsg.resetCredentialsSuccess")
        });
      } else {
        this.updateUserList({
          class: "danger",
          msg: res
        });
      }
    });
  }
  async checkIfUserCanDelete(userId: string) {
    if (!this.getUserAccess.Access.EDIT.usersList.actions.delete) return;

    this.confirm(this.$t("users.adminActionMsg.deleteUserConfirmation").toString(), async () => {
      let projectRes = await Projects.allProjectsForUser(userId);

      if (projectRes.status === 200) {
        if (projectRes.data.projectList.length === 0) {
          this.deleteUser(userId);
        } else {
          this.updateUserList({
            class: "danger",
            msg: this.$t("users.newUserForm.formStatusMsg.deleteError")
          });
        }
      } else {
        this.updateUserList({
          class: "danger",
          msg: this.$t("users.newUserForm.formStatusMsg.globalError")
        });
      }
    });
  }

  async deleteUser(userId: any) {
    let res = await ibomadeUserService.deleteUser(userId);

    if (res.status === 204) {
      this.updateUserList({
        class: "success",
        msg: this.$t("users.newUserForm.formStatusMsg.deleteSuccess")
      });
    } else {
      this.updateUserList({
        class: "danger",
        msg: this.$t("users.newUserForm.formStatusMsg.globalError")
      });
    }
  }

  updateUserList(value: any) {
    this.$emit("updateUserList", value);
  }
  async openModal(userId: string) {
    let res = await ibomadeUserService.getUser(`${userId}`);
    if (res.status === 200) {
      this.$emit("openModal", res.data);
    } else {
      this.updateUserList({
        class: "danger",
        msg: res
      });
    }
  }
}
